import React from "react";
import { Stack, Text } from "@fluentui/react";
import styled from "styled-components";

export default function Delivery() {
    return (
        <StyledStack horizontalAlign={'center'}>
            <StyledText>
                Gerne können die Maschinen/Geräte auch geliefert und abgeholt werden.<br />
                BItte entnehmen Sie die Preise der unten stehenden Tabelle, die Kilometer werden anhand Google Maps berechnet.
            </StyledText>

            <Table>
                <thead>
                    <WhiteTableRow>
                        <TableHeaderCell><StyledText>Kilometer</StyledText></TableHeaderCell>
                        <TableHeaderCell><StyledText>Preis Netto</StyledText></TableHeaderCell>
                    </WhiteTableRow>
                </thead>
                <tbody>
                    <OrangeTableRow>
                        <TableCell><StyledText>5</StyledText></TableCell>
                        <TableCell><StyledText>30 €</StyledText></TableCell>
                    </OrangeTableRow>
                    <WhiteTableRow>
                        <TableCell><StyledText>10</StyledText></TableCell>
                        <TableCell><StyledText>40 €</StyledText></TableCell>
                    </WhiteTableRow>
                    <OrangeTableRow>
                        <TableCell><StyledText>15</StyledText></TableCell>
                        <TableCell><StyledText>50 €</StyledText></TableCell>
                    </OrangeTableRow>
                    <WhiteTableRow>
                        <TableCell><StyledText>20</StyledText></TableCell>
                        <TableCell><StyledText>60 €</StyledText></TableCell>
                    </WhiteTableRow>
                    <OrangeTableRow>
                        <TableCell><StyledText>25</StyledText></TableCell>
                        <TableCell><StyledText>70 €</StyledText></TableCell>
                    </OrangeTableRow>
                    <WhiteTableRow>
                        <TableCell><StyledText>30</StyledText></TableCell>
                        <TableCell><StyledText>80 €</StyledText></TableCell>
                    </WhiteTableRow>
                    <OrangeTableRow>
                        <TableCell><StyledText>mehr als 30 km</StyledText></TableCell>
                        <TableCell><StyledText>Auf Anfrage</StyledText></TableCell>
                    </OrangeTableRow>
                </tbody>
            </Table>
        </StyledStack >
    );
}

const StyledStack = styled(Stack)({
    marginTop: '2vh',
    textAlign: 'center'
});

const Table = styled.table({
    marginTop: '2vh',
    width: '40vw',
    border: '1px solid #000',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    borderRadius: '4vw',
    padding: '0px',
});

const TableCell = styled.td({
    border: '1px solid black',
    borderCollapse: 'collapse',
    width: '20vw',
});

const TableHeaderCell = styled.th({
    border: '1px solid black',
    borderCollapse: 'collapse',
    width: '20vw',
});

const WhiteTableRow = styled.tr({
    height: '1vh'
});

const OrangeTableRow = styled(WhiteTableRow)({
    backgroundColor: '#F9550D'
});

const StyledText = styled(Text)({
    fontSize: '1rem',
    fontWeight: '600'
});
